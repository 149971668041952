import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import Img from "gatsby-image/withIEPolyfill";
import BackgroundImage from "gatsby-background-image";

import { FaMapMarkedAlt } from "react-icons/fa";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const Projects = (props) => {
  const intl = useIntl();
  const projects = props.data.allMarkdownRemark.edges;

  return (
    <Layout bodyClass='page-projects' path={props.location.pathname}>
      <SEO title={intl.formatMessage({ id: "Projects_title" })} />
      <div className='services-img w-100'></div>
      <div id='projects_intro'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 my-2 px-3'>
              <h1>
                <FormattedMessage id='Projects_title' />
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className='container projects services-tart pb-6'>
        {projects.map((edge) =>
          edge.node.frontmatter.lang == props.pageContext.intl.language ? (
            edge.node.frontmatter?.img?.childImageSharp ? (
              <>
                <Link
                  to={edge.node.frontmatter.path}
                  key={edge.node.frontmatter.path}
                >
                  <div
                    className='project project-teaser bg-transparent my-3'
                    data-sal='fade'
                    data-sal-delay='300'
                    data-sal-easing='ease'
                  >
                    <div className='row'>
                      <div className='col-md-5 order-md-2 order-1 px-0'>
                        <Img
                          className='project-kep'
                          objectFit='cover'
                          fluid={
                            edge.node.frontmatter.img.childImageSharp.fluid
                          }
                        />
                      </div>
                      <div className='col-md-7 project-tart py-3 px-3 order-md-1 order-2'>
                        <p className='text-success font-weight-bold'>
                          {edge.node.frontmatter.year}
                        </p>
                        <h4>{edge.node.frontmatter.title}</h4>
                        <p>
                          {edge.node.frontmatter.summary || edge.node.excerpt}
                        </p>
                        {edge.node.frontmatter.location ? (
                          <p>
                            <FaMapMarkedAlt color='grey' />{" "}
                            <span>{edge.node.frontmatter.location}</span>
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <Link
                  to={edge.node.frontmatter.path}
                  key={edge.node.frontmatter.path}
                >
                  <div
                    className='project bg-transparent project-teaser p-2 my-3 '
                    data-sal='fade'
                    data-sal-delay='300'
                    data-sal-easing='ease'
                  >
                    <p className='text-success font-weight-bold'>
                      {edge.node.frontmatter.year}
                    </p>
                    <h4>{edge.node.frontmatter.title}</h4>
                    <p>{edge.node.frontmatter.summary || edge.node.excerpt}</p>
                    {edge.node.frontmatter.location ? (
                      <p>
                        <FaMapMarkedAlt color='grey' />{" "}
                        <span>{edge.node.frontmatter.location}</span>
                      </p>
                    ) : null}
                  </div>
                </Link>
              </>
            )
          ) : null
        )}
      </div>
    </Layout>
  );
};

const projectsQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { active: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            title
            path
            lang
            year
            location
            summary
            img {
              childImageSharp {
                fluid(quality: 70, maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={projectsQuery}
    render={(data) => (
      <Projects location={props.location} props data={data} {...props} />
    )}
  />
);
